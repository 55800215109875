<template>
  <div>
	<br/>
    <h1 class="main-title">Пересобрать запросы</h1>
	<br/>
	<form class="resave-form" @submit.prevent="submit">
		<h2 class="resave-form__title">Добавление задачи</h2>
		<br/>
		<v-autocomplete
			v-model="selectedProject"
			:items="projects"
			outlined
			dense
			hide-details="auto"
			light
			clearable
			return-object
			item-text="name"
			item-value="name"
			label="Выберите проект"
			:filter="filterFunction"
			:rules="[rules.required]"
		>
			<template v-slot:item="{ item }">
				<span><sup>{{item.id}}</sup> {{item.name}}</span>
			</template>
		</v-autocomplete>
		<br/>
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			:nudge-right="40"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="date"
					label="Выберите дату"
					prepend-icon="mdi-calendar"
					readonly
					outlined
					hide-details="auto"
					dense
					:rules="[rules.required]"
					v-bind="attrs"
					v-on="on"
				/>
			</template>
			<v-date-picker
				v-model="date"
				locale="ru"
				first-day-of-week="1"
				no-title
				:max="new Date().toISOString().substr(0, 10)"
				@input="menu = false"
			></v-date-picker>
		</v-menu>
		<br/>
		<v-autocomplete
			v-model="selectedSE"
			:items="searchSystems"
			outlined
			dense
			light
			hide-details="auto"
			return-object
			:rules="[rules.required]"
			label="Выберите поисковую систему"
		/>
		<br/>
		<div class="d-flex flex-column">
			<span class="resave-form__queries-count align-self-end" :class="{'text--disabled': !queriesArray.length}">{{ queriesArray.length + '/50' }}</span>
			<v-textarea
				label="Список запросов"
				clearable
				outlined
				dense
				:rules="[rules.required, rules.maxQueries]"
				v-model.trim="queries"
			/>
		</div>
		<br/>
		<br/>
		<v-divider />
		<br/>
		<v-btn
			class="white--text"
			:class="{'resave-form__submit-disabled': loading || !isValid}"
			color="blue darken-1"
			:loading="loading"
			type="submit"
		>
			Запустить
		</v-btn>
	</form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'Проекты',
			menu: false,
			loading: false,
			selectedProject: null,
			date: new Date().toISOString().substr(0, 10),
			selectedSE: {
				value: 'yandex',
				text: 'Яндекс'
			},
			queries: '',
			queriesLimit: 50,

			searchSystems: [
				{
					value: 'yandex',
					text: 'Яндекс'
				},
				{
					value: 'google',
					text: 'Google'
				}
			],

			rules: {
				required: value => !!value || 'Поле обязательно',
				maxQueries: value => this.getQueriesArray(value).length <= this.queriesLimit || 'Лимит запросов исчерпан'
			}
		}
	},

	computed: {
		...mapGetters({
			projects: 'save/projects'
		}),

		queriesArray () {
			return this.getQueriesArray(this.queries)
		},

		isValid () {
			return this.selectedProject && this.selectedProject.id && this.date && this.selectedSE &&
				this.selectedSE.value && this.queriesArray.length && this.queriesArray.length <= this.queriesLimit
		}
	},

	methods: {
		...mapActions({
			getProjects: 'save/getProjects',
			resaveQueriesCH: 'save/resaveQueriesCH'
		}),

		filterFunction (item, queryText, itemText) {
			return itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.id.toString().indexOf(queryText.trim().toString()) > -1
		},

		getQueriesArray (value) {
			return value ? value.split(/\r?\n|\r|\n/g) : []
		},

		async submit () {
			if (this.isValid) {
				try {
					this.loading = true
					await this.resaveQueriesCH({
						body: {
							project_id: this.selectedProject.id,
							queries: this.queriesArray,
							source: this.selectedSE.value,
							date: this.date,
							force: new Date().getHours()
						}
					}).then(() => {
						this.$notify({ type: 'success', title: 'Запросы(ы) успешно отправлен(ы)' })
						this.success = true
					})
				} catch (err) {
					this.$notify({ type: 'error', title: err.message })
				}
				this.loading = false
			}
		}
	},

	async mounted () {
		try {
			if (!this.projects.length) {
				this.getProjects({ date: this.date })
			}
		} catch (err) {
			this.$notify({ type: 'error', title: err.message })
		}
	}
}
</script>
<style lang="scss" scoped>
.resave-form {
	max-width: 500px;

	&__title {
		font-size: 18px;
		font-weight: 700;
	}

	&__queries-count {
		font-size: 12px;
		padding: 2px 8px;
		background-color: #f1f7fd;
		border-radius: 6px 6px 0 0;
	}

	&__submit-disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}

::v-deep .v-input--dense {
	font-size: 14px;

	.v-label {
		font-size: inherit;
	}
}
</style>
